import { Injectable } from '@angular/core';
import { ResponseToken } from 'src/app/model/interfaces/response.interface';
import { MailtasticAuthorizationDetails } from 'src/app/model/enums/mailtastic-authorization-details.enum';
import { Observable, of, tap } from 'rxjs';
import { CustomerHasFreeSubscription, CustomerSubscriptionData } from 'src/app/model/interfaces/payment.interface';
import { IAccountData, SuperAdminAccountData } from 'src/app/model/interfaces/account-admin.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { IUserLoginSuccess } from '@services/user/user-service.interface';

export const SUBSCRIPTION_DATA_CACHE = 'SaaS';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private authService: AuthService, private http: HttpClient) {}

  /**
   * Refresh JWT Token to continue login
   */
  refreshJwtToken(): void {
    this.http
      .post<ResponseToken>('/account/authenticate/refreshJwtToken', {
        email: this.authService.getLocalStorage(MailtasticAuthorizationDetails.email)
      })
      .pipe(tap(res => this.authService.setToken(res.token)))
      .subscribe();
  }

  /**
   * Store the account data of external admin in localstorage
   * @param companyName - The selected company name
   * @param firstName - The firstname of logged in user
   * @param lastName - The lastname of logged in user
   * @param email - The email of logged in user
   * @param adminId - The admin id of logged in user
   * @param accountId - The default accountId of logged in user
   * @param activeAccountId - Active accountId of logged in user (External admin)
   */
  setUserAccountData(
    companyName: string,
    firstName: string,
    lastName: string,
    email?: string,
    adminId?: string,
    accountId?: string,
    activeAccountId?: string
  ): void {
    this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.companyName, companyName);
    this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.firstName, firstName);
    this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.lastName, lastName);
    if (email) this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.email, email);
    if (adminId) this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.adminId, adminId);
    if (accountId) this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.accountId, accountId);
    if (activeAccountId)
      this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.activeAccountId, activeAccountId);
  }

  /**
   * Store the account data of external admin in localstorage
   * @param result - The user login data
   */
  setSuperAdminAccountData(result: IUserLoginSuccess): void {
    const superAdminData = {
      companyName: result.companyName,
      email: result.email,
      firstname: result.firstname,
      isSuperAdmin: result.isSuperAdmin,
      lastname: result.lastname,
      token: result.token,
      userId: result.userId
    };
    localStorage.setItem(MailtasticAuthorizationDetails.superAdminData, JSON.stringify(superAdminData));
  }

  /**
   * Gets the account data of external admin from localstorage
   * @returns The object of user data
   */
  getSuperAdminAccountData(): SuperAdminAccountData {
    const superAdmin = localStorage.getItem(MailtasticAuthorizationDetails.superAdminData) || '';
    if (superAdmin !== '') {
      const parseData = JSON.parse(superAdmin) as SuperAdminAccountData;
      return parseData;
    }
    return {} as SuperAdminAccountData;
  }

  /**
   * Gets the account data of external admin from localstorage
   * @returns The object of user data
   */
  getUserAccountData(): IAccountData {
    return {
      companyName: this.authService.getLocalStorage(MailtasticAuthorizationDetails.companyName),
      firstName: this.authService.getLocalStorage(MailtasticAuthorizationDetails.firstName),
      lastName: this.authService.getLocalStorage(MailtasticAuthorizationDetails.lastName),
      email: this.authService.getLocalStorage(MailtasticAuthorizationDetails.email),
      userId: this.authService.getLocalStorage(MailtasticAuthorizationDetails.userId),
      accountId: this.authService.getLocalStorage(MailtasticAuthorizationDetails.accountId),
      defaultUserId: this.authService.getLocalStorage(MailtasticAuthorizationDetails.defaultUserId),
      activeAccountId: this.authService.getLocalStorage(MailtasticAuthorizationDetails.activeAccountId)
    };
  }

  /**
   * Get account subscription data from localStorage item or object of CustomerHasFreeSubscription
   * @returns Object of CustomerSubscriptionData | CustomerHasFreeSubscription
   */
  getCustomerSubscriptionData(): Observable<CustomerSubscriptionData | CustomerHasFreeSubscription> {
    const cachedSaaS = JSON.parse(localStorage.getItem(SUBSCRIPTION_DATA_CACHE) || '{}') as CustomerSubscriptionData;
    if (Object.keys(cachedSaaS).length) {
      return of(cachedSaaS);
    }
    return of({ hasFreeSubscription: true });
  }

  /**
   * Set the account subscription data to the localStorage item
   * @param res - Object of CustomerSubscriptionData
   */
  setSubscriptionDataCache(res: CustomerSubscriptionData): void {
    localStorage.setItem(SUBSCRIPTION_DATA_CACHE, JSON.stringify(res));
  }

  /**
   * Remove the account subscription data to the localStorage item
   */
  removeSubscriptionDataCache(): void {
    localStorage.removeItem(SUBSCRIPTION_DATA_CACHE);
  }
}
